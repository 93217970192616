import _defineProperty from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { createStandaloneToast, theme, useToast } from '@chakra-ui/react';
import { Box } from '@playful/design_system';
import { generateUUID } from '@playful/utils';
import React, { useEffect } from 'react';
import { proxy, ref, useSnapshot } from 'valtio';
var _createStandaloneToas = createStandaloneToast(),
  toast = _createStandaloneToas.toast,
  ToastContainer = _createStandaloneToas.ToastContainer;
var initialToastState = {
  toasts: []
};
export var toastState = proxy(initialToastState);
export var addToast = function addToast(toast) {
  var id = toast.id,
    _toast$status = toast.status,
    status = _toast$status === void 0 ? 'info' : _toast$status,
    title = toast.title,
    description = toast.description,
    _toast$position = toast.position,
    position = _toast$position === void 0 ? 'bottom' : _toast$position,
    duration = toast.duration;
  var exists = toastState.toasts.find(function (t) {
    return t.id === id;
  });
  if (exists) return;
  toastState.toasts.push(
  // Use Valtio's ref() to prevent messages that are ReactNodes from being proxy-wrapped
  // which seems to confuse React downstream.
  ref({
    id: id,
    title: title,
    description: description,
    status: status,
    position: position,
    duration: duration
  }));
};
export var addErrorToast = function addErrorToast(message, options) {
  var t = _objectSpread({
    id: generateUUID(),
    description: typeof message !== 'string' ? JSON.stringify(message) : message,
    status: 'error'
  }, options);
  addToast(t);
};
export var addSuccessToast = function addSuccessToast(message, options) {
  var t = _objectSpread({
    id: generateUUID(),
    description: message,
    status: 'success'
  }, options);
  addToast(t);
};
export var addInfoToast = function addInfoToast(message, options) {
  var t = _objectSpread({
    id: generateUUID(),
    description: message,
    status: 'info'
  }, options);
  addToast(t);
};
export var removeToast = function removeToast(toastId) {
  toastState.toasts = toastState.toasts.filter(function (t) {
    return t.id !== toastId;
  });
  toast.close(toastId);
};
export default function AppToasts() {
  var _useSnapshot = useSnapshot(toastState),
    toasts = _useSnapshot.toasts;
  var toast = useToast();
  useEffect(function () {
    toasts.map(function (t) {
      var customToast = function customToast() {
        return __jsx(Box, {
          backgroundColor: 'gray.500',
          color: 'white',
          fontSize: 'xs',
          fontWeight: 'bold',
          borderRadius: 'full',
          px: 3,
          py: 2,
          marginBottom: 8,
          textAlign: 'center',
          boxShadow: 'md'
        }, t.title, t.description);
      };
      var toastOptions = {
        id: t.id,
        status: t.status,
        title: t.title,
        description: t.description,
        duration: t.duration,
        position: t.position,
        variant: 'left-accent',
        isClosable: true,
        containerStyle: {
          minWidth: theme.sizes[48]
        },
        onCloseComplete: function onCloseComplete() {
          removeToast(t.id);
        }
      };

      // If t.status is not provided, default to the gray custom toast
      if (t.status === undefined || t.status === 'info') {
        toastOptions.render = customToast;
      }
      if (!toast.isActive(t.id)) {
        toast(toastOptions);
      }
    });
  }, [toast, toasts, toasts.length]);
  return __jsx(ToastContainer, null);
}